import React from "react";
import styled from "styled-components";

/* eslint-disable */
import {
  up as mediaUp,
  down as mediaDown,
  between as mediaBetween,
  only as mediaOnly,
} from "styled-breakpoints";
import PageMeta from "../components/PageMeta";
/* eslint-enable */

import Fragments from "../components/Fragments";
import ContactForm from "../components/ContactForm";

const MapStyles = styled.div`
  height: 30rem;
  ${mediaUp("tablet")} {
    height: 70rem;
  }
`;

const StyledMaps = styled(Fragments.GoogleMaps)``;

const DivStyles = styled.div`
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;

  transition-property: all;
  transition-duration: 0.5s;

  input {
    input {
      background-color: white;
    }
  }

  ${mediaOnly("phone")} {
    background-color: white;
    padding: 2rem;
  }

  ${mediaUp("tablet")} {
    background-color: rgba(255, 255, 255, 0.94);
    position: absolute;
    width: 34rem;
    padding: 2rem;
    right: 1rem;
    top: 1rem;
  }
`;

export default function ContactUsPage() {
  return (
    <>
      <PageMeta title="QCL - Contact us" path="contact-us" />
      <MapStyles className="col3 p-no-main-hpadding p-no-main-tpadding t-no-main-padding">
        <StyledMaps className="full-width full-height" />
      </MapStyles>
      <DivStyles className="col3 t-dropshadow">
        <ContactForm />
      </DivStyles>
    </>
  );
}
